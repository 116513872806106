/*body {*/
/*    background-color: #fcfcfc;*/
/*    -moz-user-select: none;*/
/*    -webkit-user-select: none;*/
/*    -ms-user-select: none;*/
/*}*/

/*.sigContainer {*/
/*    background-color: aqua;*/
/*    width: 180px;*/
/*    height: 180px;*/
/*    margin: 0 auto;*/
/*    !*background-color: #fff;*!*/
/*    border: 2px dotted #CCCCCC;*/
/*    border-radius: 15px;*/
/*    cursor: crosshair;*/
/*}*/

/*.sigPad {*/
/*    background-color: #008a00;*/
/*    position: absolute;*/
/*    left: 20px;*/
/*    top: 20px;*/
/*    width:  calc(180px - (20 * 2) );*/
/*    height: calc(180px - (20 * 2) );*/
/*}*/

/*.buttons {*/
/*    width: 50%;*/
/*    height: 30px;*/
/*    border: solid 1px #ccc;*/
/*    margin-top: 15px;*/
/*    font-weight: normal;*/
/*}*/

/*.buttons:hover {*/
/*    background-color: #7c828d;*/
/*    color: #fff0f6;*/
/*    font-weight: bold;*/
/*}*/

/*.sigImage {*/
/*    background-color: rgb(0 0 0 / 0%);*/
/*}*/


body {
    background-color: #fcfcfc;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.sigContainer {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0 auto;
    background-color: #fff;
    cursor: crosshair;
}

.sigPad {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px;
    top: 20px;
    width: 140px;
    height: 140px;
    border-radius: 15px;
    border: 2px dotted #CCCCCC;
}

.buttons {
    width: 50%;
    height: 30px;
    border: solid 1px #ccc;
    margin-top: 15px;
    font-weight: normal;
}

.buttons:hover {
    background-color: #7c828d;
    color: #fff0f6;
    font-weight: bold;
}

.sigImage {
    background-color: rgb(0 0 0 / 0%);
}
