* {
    box-sizing: border-box;
}
html, body {
    height: 100%;
}


.react-draggable, .cursor {
    cursor: move;
}
.no-cursor {
    cursor: auto;
}
.cursor-y {
    cursor: ns-resize;
}
.cursor-x {
    cursor: ew-resize;
}

.react-draggable strong {
    display: block;
    text-align: center;
}

.box {
    background: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    width: 180px;
    height: 180px;
    margin: 10px;
    padding: 10px;
    float: left;
}
.no-pointer-events {
    pointer-events: none;
}
.hovered {
    background-color: gray;
}

.signInternal {
    z-index: 1000;
    width: 180px;
    height: 180px;
}

/*
 * RemWrapper needs to take it's styles from this element,
 * and this element can't have an absolute position after it's kicked in.
 * AFAIK it's not possible to do this directly in the RemWrapper component.
 */
.rem-position-fix {
    position: static !important;
}
