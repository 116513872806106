// colors
$black: #000000;
$white: #ffffff;
$black33: #333333;
$black34: #292d34;
$black93: #818193;
$redF5: #f5222d;
$redEB: #eb5757;
$greyE9: #e9ebf0;
$greyF0: #f0f0f0;
$greyEE: #eeeeee;
$greyDA: #dadada;
$greyC4: #c4c4c4;
$greyF2: #f2f2f2;
$grey62: #626262;
$grey65: #656565;
$greyCE: #cecece;
$greyE6: #e6f7ff;
$greyE6E9: #e6e9ef;
$greyF5: #f0f2f5;
$greyE8: #e8e8e8;
$grey97: #979797;
$greyF7: #f7f7f7;
$greyFA: #fafafa;
$greyD9: #d9d9d9;
$greyED: #efefed;
$grey8D: #7c828d;
$greyFC: #fafbfc;
$blue1C: #1c6ff7;
$blueFF: #000000;
$blue57: #1b1457;
$green27A: #27ae60;
$green0C: #0cac88;
$yellow6E: #ffd66e;
$yellowD4: #fff3d4;
$ant-primary: #40a9ff;

$small: 576px;
$tablet: 768px;
$desktop: 992px;
$large-desktop: 1200px;
