/** {*/
/*    box-sizing: border-box;*/
/*}*/
/*html, body {*/
/*    height: 100%;*/
/*}*/



/*.react-draggable, .cursor {*/
/*    cursor: move;*/
/*}*/
/*.no-cursor {*/
/*    cursor: auto;*/
/*}*/
/*.cursor-y {*/
/*    cursor: ns-resize;*/
/*}*/
/*.cursor-x {*/
/*    cursor: ew-resize;*/
/*}*/

/*.react-draggable strong {*/
/*    background: #ddd;*/
/*    border: 1px solid #999;*/
/*    border-radius: 3px;*/
/*    display: block;*/
/*    margin-bottom: 10px;*/
/*    padding: 3px 5px;*/
/*    text-align: center;*/
/*}*/

/*.box {*/
/*    background: #fff;*/
/*    border: 1px solid #999;*/
/*    border-radius: 3px;*/
/*    width: 180px;*/
/*    height: 180px;*/
/*    margin: 10px;*/
/*    padding: 10px;*/
/*    float: left;*/
/*}*/
/*.no-pointer-events {*/
/*    pointer-events: none;*/
/*}*/
/*.hovered {*/
/*    background-color: gray;*/
/*}*/


/*!**/
/* * RemWrapper needs to take it's styles from this element,*/
/* * and this element can't have an absolute position after it's kicked in.*/
/* * AFAIK it's not possible to do this directly in the RemWrapper component.*/
/* *!*/
/*.rem-position-fix {*/
/*    position: static !important;*/
/*}*/
