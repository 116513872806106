

.test {
    position: absolute;
    top: 100px;
    left: 300px;
    margin: 0 auto;
    background-color: #fff;
    border: 2px dotted #CCCCCC;
    border-radius: 15px;
    cursor: crosshair;
    width: 100px;
    height: 100px;
}

.box {
    margin: 0;
    padding: 0;
}

.shadow {
    width: 180px;
    height: 180px;
}

.sigImage {
    background-color: rgb(0 0 0 / 0%);
}

#e-contract-doc {
    border: solid 1px #ccc;
}
