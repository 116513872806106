.sider {
  background-color: #2B2A77 !important;
  min-width: 220px !important;
  padding: 5px;
  &__menu {
    background-color: #2B2A77 !important;

    .ant-menu-item-selected {
      background-color: #1E1D53 !important;
      color: #FFFFFF;
    }

    .ant-menu-sub {
      background-color: #2B2A77 !important;
    }
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0px !important;
  }

  &__logo {
    margin: 20px;
  }

  &__user {
    position: fixed;
    bottom: 0;
    margin: 20px;

    &__content {
      display: flex;
      color: #FFFFFF;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__info {
        display: flex;
        flex-direction: column;
        margin: 5px;
      }
    }
  }
}

.ant-layout-sider-trigger {
  display: none;
}

.site-layout {
  background-color: #F1F2F8;
}

.breadcrumb {
  margin: 30px 40px;
}

.body {
  padding: 0 30px;
}