.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-pdf__Page__textContent {
  display: none;
  height: 0 !important;
}
.react-pdf__Page__annotations {
  height: 0 !important;
  display: none;
}
.react-pdf__Page__canvas {
  border: 1px solid #e5e7eb !important;
}
.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-editor {
  min-height: 3em;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.sticky-button {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 24px 0 1px 0;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #FFFFFF;
}

.short-text {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pre-sign-border {
  height: 214px;
  width: 25px;
  position: absolute;
  left: -16px;
  top: 4px;
}
